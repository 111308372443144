import { ItemType } from "@simbelapp/order-sdk";
import { FilterEnums, ItemType as SettingItemType, SettingScopesEnum } from "@simbelapp/auth-sdk";

import { useState } from "#imports";
import { useFetchApi, type MicroServiceApiType } from "~/composables/api/useFetchApi";
import { usePagination } from "~/composables/paginations/usePagination";
import { MicroserviceEnum } from "~~/utils/enums/common.enums";
import { useTeamsCookies } from "~~/composables/cookies/useTeamsCookies";
import { useRudderstack } from "~~/composables/utils/useRudderstack";
import { useAuthStore } from "~/store/auth/auth.store";
import { useCreateCompaniesStore } from "~/store/companies/create-company.store";
import { useSettingsStore } from "~/store/settings/settings.store";

export interface IFilterOption {
  filter_id: string;
  default: boolean;
  available: boolean;
  active: boolean;
  values?: Array<any>;
  selected_values: Array<any>;
  filteredValues?: Array<any>;
}
interface IFilterCategory {
  training: Set<IFilterOption>;
  employee: Set<IFilterOption>;
  requests: Set<IFilterOption>;
  habilitation: Set<IFilterOption>;
  companyCatalog: Set<IFilterOption>;
  catalog: Set<IFilterOption>;
}

interface IFilterCategory {
  training: Set<IFilterOption>;
  employee: Set<IFilterOption>;
  requests: Set<IFilterOption>;
  habilitation: Set<IFilterOption>;
  catalog: Set<IFilterOption>;
}

const OPTIONS: IFilterCategory = {
  training: new Set(),
  employee: new Set(),
  requests: new Set(),
  habilitation: new Set(),
  companyCatalog: new Set(),
  catalog: new Set(),
};

enum FilterSectionsEnum {
  TRAINING = "training",
  EMPLOYEE = "employee",
  REQUESTS = "requests",
  HABILITATION = "habilitation",
  COMPANY_CATALOG = "companyCatalog",
  CATALOG = "catalog",
}

export type FetchType =
  | "individual"
  | "sessions"
  | "requests"
  | "users"
  | "habilitations"
  | "companyCatalog"
  | "training"
  | "catalog";

type ExtendedItemType = {
  sessions: typeof ItemType.Session;
  individual: typeof ItemType.Session;
  training: typeof ItemType.Training;
  companyCatalog?: any;
  habilitations?: any;
  requests?: any;
  users?: any;
  catalog?: any;
};

export const useFilters = () => {
  const showFiltersOptions = useState<Boolean>("filters_show", () => false);
  const filtersOptions = useState<IFilterCategory>("filters_categories", () => OPTIONS);
  const activeFilters = useState<Set<IFilterOption>>("filters_active", () => new Set());
  const endpoint = useState<string>("endpoint_type", () => "");
  const filtersType = useState<FetchType>("filters_type", () => "requests");
  const microservice = useState<MicroServiceApiType>("microservice", () => MicroserviceEnum.ORDERS);
  const teamsCookies = useTeamsCookies();
  const activeCookie = useState<string | null>("filters_opts", () => null);
  const authStore = useAuthStore();
  const { user } = storeToRefs(authStore);
  const createCompaniesStore = useCreateCompaniesStore();
  const route = useRoute();
  const settingsStore = useSettingsStore();
  const { gridDetails } = storeToRefs(settingsStore);

  const endpoints: Record<FetchType, string> = {
    sessions: "/TrainingPlan/TrainingPlanViewSession",
    individual: "/TrainingPlan/SessionTrainingPlan",
    requests: "/requests",
    users: "/users",
    habilitations: "/habilitations",
    companyCatalog: "/companyCatalog",
    catalog: "/companyCatalog/catalog",
    training: "/trainings/simbel-trainings",
  };

  function reset() {
    filtersOptions.value.employee.clear();
    filtersOptions.value.training.clear();
    filtersOptions.value.companyCatalog.clear();
    filtersOptions.value.requests.clear();
    filtersOptions.value.habilitation.clear();

    activeFilters.value.clear();
    showFiltersOptions.value = false;
  }

  async function fetchValues(filter_id: string): Promise<any[]> {
    const itemType: ExtendedItemType = {
      sessions: ItemType.Session,
      individual: ItemType.Session,
      training: ItemType.Training,
    };

    if (["training_tags"].includes(filter_id)) {
      if (user.value?.main_entity_id) {
        const data = await createCompaniesStore.getTrainingTags({
          company_id: user.value.main_entity_id.company_id,
          onlyUsed: "true",
          itemType: itemType[filtersType.value],
          onlyCatalogFilterVisible: route.path === "/catalog" ? "true" : undefined,
        });
        if (data) {
          const transformedData = data.map((tag) => {
            return {
              ...tag,
              id: tag?.tagId,
            };
          });
          return transformedData;
        }
      }
    } else {
      const { data } = await useFetchApi(microservice.value, `${endpoint.value}/filters/${filter_id}`);
      if (data.value) {
        return data.value as [];
      }
    }

    return [];
  }

  function setMicroservice(ms: MicroServiceApiType, cookie?: string | null) {
    reset();
    microservice.value = ms;
    if (cookie) activeCookie.value = cookie;
  }

  async function getCookieFilter(filter_id: string) {
    // const cookie = useCookie(`${ cookies_type.value }_${ COOKIE_PREFIX }_${ filter_id }`);
    const pageOpts = await teamsCookies.getQueryOpts(activeCookie.value);
    if (pageOpts && pageOpts[filter_id]) {
      return pageOpts[filter_id]
        .toString()
        .split(",")
        .map((val: any) => {
          return decodeURIComponent(val);
        });
    }
    return null;
  }
  function getFilter(filter_id: string) {
    const existingFilter = gridDetails.value.filtersValues.find((f) => f.filterId === filter_id);
    return existingFilter?.values ?? null;
  }

  async function fetchFilters(type: FetchType, cookie_save?: boolean) {
    filtersType.value = type;
    endpoint.value = endpoints[type];

    const { data } = await useFetchApi(microservice.value, `${endpoint.value}/filters`);

    if (data.value) {
      reset();

      data.value
        .find((section: any) => section.section_id === FilterSectionsEnum.TRAINING)
        ?.filters?.forEach((f: any) => filtersOptions.value.training.add(f));
      data.value
        .find((section: any) => section.section_id === FilterSectionsEnum.EMPLOYEE)
        ?.filters?.forEach((f: any) => filtersOptions.value.employee.add(f));
      data.value
        .find((section: any) => section.section_id === FilterSectionsEnum.REQUESTS)
        ?.filters?.forEach((f: any) => filtersOptions.value.requests.add(f));
      data.value
        .find((section: any) => section.section_id === FilterSectionsEnum.HABILITATION)
        ?.filters?.forEach((f: any) => filtersOptions.value.habilitation.add(f));
      data.value
        .find((section: any) => section.section_id === FilterSectionsEnum.COMPANY_CATALOG)
        ?.filters?.forEach((f: any) => filtersOptions.value.companyCatalog.add(f));
      data.value
        .find((section: any) => section.section_id === FilterSectionsEnum.CATALOG)
        ?.filters?.forEach((f: any) => filtersOptions.value.companyCatalog.add(f));

      for (const opts of Object.values(filtersOptions.value)) {
        for (const val of opts) {
          let values: string[] | null = null;
          if (cookie_save) {
            values = (await getCookieFilter(val.filter_id)) ?? null;
          } else {
            values = (await getFilter(val.filter_id)) ?? null;
          }
          if (val.default || values) {
            await addFilter(val, values);
          }
        }
      }
    }
  }

  async function addFilter(filter: IFilterOption, default_values?: string[] | null) {
    /**
     * this filters list excluded element who don't need to fetch datas per example date or price
     * contract_start_date is a datepicker, it's only range and without limit of data.
     */
    const excludedFilters = ["contract_start_date", "session_start_date"];

    if (filter.available) {
      if (!filter.values?.length && !excludedFilters.includes(filter.filter_id)) {
        filter.values = await fetchValues(filter.filter_id);
      }

      filter.selected_values = [];

      if (default_values) {
        if (
          [
            "trainings_admin",
            "period",
            "roles",
            "session_tag",
            "training_tag",
            "training_tags",
            "habilitation_name",
          ].includes(filter.filter_id)
        ) {
          if (filter.values) {
            filter.selected_values = filter.values.filter((x) => default_values.includes(x === null ? "null" : x.id));
          }
        } else {
          filter.selected_values = default_values.map((x) => (x === "null" ? null : x));
        }
      }

      const rudderstack = useRudderstack();
      rudderstack.track("add_filter", {
        filter_id: filter.filter_id,
      });

      activeFilters.value.add(filter);
    }
  }

  async function clearFilters(cookie?: boolean) {
    if (cookie) {
      activeFilters.value.forEach((filter) => {
        teamsCookies.clearQueryOpt(filter.filter_id, activeCookie.value);
      });

      const rudderstack = useRudderstack();

      rudderstack.track("clear_filter", {
        filter: "none",
      });
    } else {
      gridDetails.value.filtersValues = [];
      if (authStore.user?.user_id) {
        await settingsStore.updateGridSettings(authStore.user?.user_id, SettingItemType.User);
      }
    }

    activeFilters.value.clear();
    updateFilters();
  }

  function filtersResetOffset(cookie_save?: boolean) {
    const paginate = usePagination();
    paginate.paginateOptions.value.offset = 0;
    if (cookie_save) {
      teamsCookies.setQueryOpts("offset", 0, activeCookie.value);
    } else {
      gridDetails.value.paginationValues = [];
      if (authStore.user?.user_id) {
        settingsStore.updateGridSettings(authStore.user?.user_id, SettingItemType.User);
      }
    }
  }

  async function setCookieFilterOpt(filter: IFilterOption) {
    await teamsCookies.setFiltersQueryOpts(filter, activeCookie.value);
    filtersResetOffset(true);
    updateFilters();
  }

  async function setFilterOpt(filter: IFilterOption, scope?: SettingScopesEnum) {
    if (!scope) {
      setCookieFilterOpt(filter);
      return;
    }
    const filterExists = gridDetails.value.filtersValues.some(
      (existingFilter) => existingFilter.filterId === filter.filter_id,
    );
    if (!filterExists) {
      const newFilter = {
        filterId: filter.filter_id as FilterEnums,
        values: filter.selected_values.map((value) => (value?.id as string) ?? value ?? null),
      };
      gridDetails.value.filtersValues.push(newFilter);
    } else {
      const existingFilter = gridDetails.value.filtersValues.find((f) => f.filterId === filter.filter_id);

      if (existingFilter) {
        existingFilter.values = filter.selected_values.map((value) => (value?.id as string) ?? value ?? null);
      }
    }
    if (authStore.user?.user_id && scope) {
      await settingsStore.updateGridSettings(authStore.user?.user_id, SettingItemType.User);
    }
    filtersResetOffset(false);

    updateFilters();
  }

  async function clearCookieFilterOpts(active_filter: IFilterOption, skipUpdate?: boolean) {
    if (active_filter.selected_values.length) {
      await teamsCookies.clearQueryOpt(active_filter.filter_id, activeCookie.value);

      active_filter.selected_values = [];
      filtersResetOffset();
      if (!skipUpdate) updateFilters();
      // updateKpi();
    }
  }

  async function clearFilterOpts({
    active_filter,
    skipUpdate,
    cookie,
  }: {
    active_filter: IFilterOption;
    skipUpdate?: boolean;
    cookie?: boolean;
  }) {
    if (cookie) {
      clearCookieFilterOpts(active_filter, skipUpdate);
    } else if (active_filter.selected_values.length) {
      gridDetails.value.filtersValues = gridDetails.value.filtersValues.filter(
        (filter) => filter.filterId !== active_filter.filter_id,
      );
      if (authStore.user?.user_id) {
        await settingsStore.updateGridSettings(authStore.user?.user_id, SettingItemType.User);
      }
      active_filter.selected_values = [];
      filtersResetOffset();
      if (!skipUpdate) updateFilters();
      // updateKpi();
    }
  }

  function updateFilters() {
    const paginate = usePagination();
    paginate.fetchData();
  }

  function getFiltersPayload() {
    let query_string = "";
    activeFilters.value.forEach((val) => {
      query_string += query_string.length ? "&" : "";
      if (
        ["trainings_admin", "period", "roles", "session_tag", "training_tags", "habilitation_name"].includes(
          val.filter_id,
        )
      ) {
        query_string += `filters[${val.filter_id}]=${val.selected_values
          .map((x) => encodeURIComponent(x?.id ?? "null"))
          .join(",")}`;
      } else {
        query_string += `filters[${val.filter_id}]=${val.selected_values
          .map((x) => encodeURIComponent(x ?? "null"))
          .join(",")}`;
      }
    });

    return query_string;
  }

  function getFiltersForSDK() {
    const query_string: string[] = [];
    activeFilters.value.forEach((val) => {
      if (
        ["trainings_admin", "period", "roles", "session_tag", "training_tags", "habilitation_name"].includes(
          val.filter_id,
        )
      ) {
        query_string.push(`${val.filter_id}=${val.selected_values?.map((x) => x?.id || "null").join(",")}`);
      } else {
        query_string.push(`${val.filter_id}=${val.selected_values.map((x) => x || "null").join(",")}`);
      }
    });
    return { filters: query_string };
  }

  function filterValues(filter: IFilterOption, searchQuery?: string) {
    if (filter.values) {
      if (searchQuery && searchQuery.trim().length > 0) {
        filter.filteredValues = filter.values.filter((val) => {
          if (!val) {
            return false;
          } else if (val.name) {
            return val.name.toLowerCase().includes(searchQuery.trim().toLowerCase());
          } else if (val.display_value) {
            return val.display_value.toLowerCase().includes(searchQuery.trim().toLowerCase());
          } else {
            return val.toLowerCase().includes(searchQuery.trim().toLowerCase());
          }
        });
      } else {
        filter.filteredValues = filter.values;
      }
    }
  }

  return {
    showFiltersOptions,
    filtersOptions,
    activeFilters,
    filtersType,
    getFiltersForSDK,
    setMicroservice,
    addFilter,
    reset,
    clearFilters,
    fetchFilters,
    updateFilters,
    clearFilterOpts,
    getFiltersPayload,
    setFilterOpt,
    filterValues,
    setCookieFilterOpt,
    clearCookieFilterOpts,
  };
};
