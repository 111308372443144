import { type AsyncData } from "#app/composables/asyncData";
import { usePagination } from "../paginations/usePagination";
import { useFetchApi } from "~/composables/api/useFetchApi";
import { useAuthStore } from "~/store/auth/auth.store";
import type { ICreateCompanyPayload } from "~/utils/interfaces/companies-interfaces";

export const useCompaniesApi = () => {
  // Minimal amount of infos
  async function fetchCompanyLegalEntities(): Promise<AsyncData<any, any>> {
    const authStore = useAuthStore();
    return await useFetchApi("auth", `/companies/legal-entity/${authStore.user?.main_entity_id?.company_id}`, {
      method: "GET",
    });
  }

  async function fetchCompanyGroups(): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/groups`, {
      method: "GET",
    });
  }

  async function fetchCompanyInfos(): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/companies`, {
      method: "GET",
    });
  }

  async function getCompanyHiddenPrices(company_id: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/companies/catalog-settings/${company_id}`, {
      method: "GET",
    });
  }

  async function getCompanySettings(company_id: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/companies/settings/${company_id}`, {
      method: "GET",
    });
  }

  async function fetchAdminAllCompanies(): Promise<AsyncData<any, any>> {
    const pagination = usePagination();

    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);

    const paginate_query = new URLSearchParams(paginate_options).toString();

    return await useFetchApi("auth", `/admin/companies?${paginate_query}`, {
      method: "GET",
    });
  }

  async function createCompany(createCompanyPayload: ICreateCompanyPayload): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/admin/companies`, {
      method: "POST",
      body: createCompanyPayload,
    });
  }

  async function updateCompany(
    createCompanyPayload: ICreateCompanyPayload,
    companyId: string,
  ): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/admin/companies/${companyId}`, {
      method: "PATCH",
      body: createCompanyPayload,
    });
  }

  return {
    fetchCompanyLegalEntities,
    fetchCompanyGroups,
    fetchCompanyInfos,
    updateCompany,
    getCompanySettings,
    fetchAdminAllCompanies,
    createCompany,
    getCompanyHiddenPrices,
  };
};
