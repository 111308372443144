import { defineStore } from "pinia";
import dateFnsTz from "date-fns-tz";
import { CompaniesApi } from "@simbelapp/auth-sdk/apis/CompaniesApi";
import { AdminCompaniesApi } from "@simbelapp/auth-sdk";
import { ItemType, TagsApi } from "@simbelapp/order-sdk";
import { useAuthStore } from "../auth/auth.store";
import { useCompaniesApi } from "~~/composables/companies/useCompaniesApi";
import type { ICreateCompanyStore } from "~~/utils/interfaces/companies-interfaces";
import { CompanyIdentificationModeEnum, CompanyAuthStrategy, CompanyLanguage } from "~~/utils/enums/companies.enums";
import { useFeedback } from "~~/composables/feedback/useFeedback";
import { useSDKApi } from "~/composables/api/useSDKApi";
import { MicroserviceEnum } from "~/utils/enums/common.enums";
import { TagCategoryEnum } from "~/utils/enums/tags.enums";

export const useCreateCompaniesStore = defineStore("useCreateCompaniesStore", {
  state: (): ICreateCompanyStore => {
    return {
      create_company: {
        name: "",
        manager_validation: false,
        identification_mode: CompanyIdentificationModeEnum.EMAIL,
        is_main_entity: true,
        email_admin: "",
        auth_strategy: CompanyAuthStrategy.LOGIN_PASSWORD,
        notifications: {
          new_employee_request: true,
          refused_employee_request: true,
          waiting_hr_validation: true,
          booking_to_be_confirmed: true,
          booking_confirmed: true,
          booking_dates_updated: true,
        },
        hide_catalog_prices: false,
        trainings_not_billable: false,
        language: CompanyLanguage.FR,
      },
      families_tag: [],
      training_tags: [],
      session_tags: [],
      logo_url: null,
      logo: null,
      sso_provider: null,
      errors: {},
      show_create_modal: false,
      is_update: false,
      showDeleteTagModal: false,
      showDeleteDashboardModal: false,
    };
  },
  getters: {},
  actions: {
    openCreateCompanyModal() {
      this.$reset();
      this.show_create_modal = true;
    },

    async validateCompanyCreation() {},

    async createCompany() {
      const companiesApi = useCompaniesApi();
      const { utcToZonedTime } = dateFnsTz;

      if (!this.create_company?.welcome_message) {
        this.create_company.welcome_message = `Bienvenue sur votre académie de formation`;
      }

      if (this.create_company?.start_sync_date)
        this.create_company.start_sync_date = utcToZonedTime(this.create_company.start_sync_date, "Etc/UTC");

      const { data } = await companiesApi.createCompany(this.create_company);
      if (data.value) {
        await this.updateCompanyLogo(data.value.company_id);
      }
      return data.value;
    },

    async getCompany() {
      const companiesApi = useCompaniesApi();
      const { data } = await companiesApi.createCompany(this.create_company);
      return data.value;
    },

    async getTrainingTags({
      company_id,
      onlyUsed,
      itemType,
      nullable,
      onlyCatalogFilterVisible,
    }: {
      company_id?: string;
      onlyUsed?: string;
      itemType?: ItemType;
      nullable?: boolean;
      onlyCatalogFilterVisible?: string;
    }) {
      if (this.create_company.company_id !== undefined || company_id !== undefined) {
        const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TagsApi);
        const training_tag = await apiInstance.getCompanyTags({
          companyId: this.create_company.company_id ?? (company_id as string),
          category: TagCategoryEnum.TRAINING_TAG,
          onlyUsed,
          itemType,
          onlyCatalogFilterVisible,
        });
        this.training_tags = nullable ? training_tag.filter((e) => e !== null) : training_tag;
        return training_tag;
      }
    },
    async getSessionTags(company_id?: string, nullable?: boolean) {
      if (this.create_company.company_id !== undefined || company_id !== undefined) {
        const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TagsApi);
        const session_tag = await apiInstance.getCompanyTags({
          companyId: this.create_company.company_id ?? (company_id as string),
          category: TagCategoryEnum.SESSION_TAG,
        });
        this.session_tags = nullable ? session_tag.filter((e) => e !== null) : session_tag;
      }
    },
    async getFamiliesTag() {
      const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TagsApi);
      const response = await apiInstance.getTagFamilies();
      this.families_tag = response;
      return response;
    },

    async deleteTagFamily(id: string) {
      const authStore = useAuthStore();
      const main_entity_id = authStore.user?.main_entity_id;
      const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TagsApi);
      const response = await apiInstance.deleteTagFamily({
        tagFamilyId: id,
      });
      await this.getFamiliesTag();
      await this.getTrainingTags({ company_id: main_entity_id?.company_id, nullable: true });
      return response;
    },

    async createTagFamily(name?: string) {
      const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TagsApi);
      const response = await apiInstance.createTagFamily({
        updateTagFamilyDto: {
          tagFamilyName: name || null,
        },
      });
      await this.getFamiliesTag();
      return response;
    },

    async updateTagFamily({
      id,
      name,
      catalogFilterVisible,
    }: {
      id: string;
      name?: string;
      catalogFilterVisible?: boolean;
    }) {
      const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TagsApi);
      const response = await apiInstance.updateTagFamily({
        tagFamilyId: id,
        updateTagFamilyDto: {
          tagFamilyName: name,
          catalogFilterVisible,
        },
      });
      this.getFamiliesTag();
      return response;
    },

    async postTrainingTags(company_id?: string) {
      if (this.create_company.company_id !== undefined || company_id !== undefined) {
        const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TagsApi);
        const training_tag = await apiInstance.setCompanyTags({
          companyId: this.create_company.company_id ?? (company_id as string),
          category: TagCategoryEnum.TRAINING_TAG,
          setCompanyTagsDto: this.training_tags,
        });
        await this.getTrainingTags({});
        return { training_tag };
      }
    },

    async postSessionTags(company_id?: string) {
      if (this.create_company.company_id !== undefined || company_id !== undefined) {
        const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TagsApi);
        const session_tag = await apiInstance.setCompanyTags({
          companyId: this.create_company.company_id ?? (company_id as string),
          category: TagCategoryEnum.SESSION_TAG,
          setCompanyTagsDto: this.session_tags,
        });
        await this.getSessionTags();
        return { session_tag };
      }
    },

    async convertCompanyLogo(logo: File) {
      const apiInstance = await useSDKApi(MicroserviceEnum.USERS, CompaniesApi);
      const response = await apiInstance.convertCompanyLogoRaw({
        file: logo,
      });
      const convertedImg = await response.raw.blob();
      if (convertedImg) {
        this.logo = new File([convertedImg], logo.name, { type: "image/png" });
      }
    },

    async updateCompanyLogo(companyId: string) {
      const apiInstance = await useSDKApi(MicroserviceEnum.USERS, AdminCompaniesApi);
      const data = await apiInstance.updateCompanyLogo({
        file: this.logo ?? undefined,
        companyId,
      });

      if (data.url) {
        this.logo_url = data.url;
      }
    },

    async fetchCompanySettings(company_id: string) {
      const apiInstance = await useSDKApi(MicroserviceEnum.USERS, CompaniesApi);
      const companiesApi = useCompaniesApi();
      const { data } = await companiesApi.getCompanySettings(company_id);
      const hiddenPrices = await companiesApi.getCompanyHiddenPrices(company_id);
      const { language } = await apiInstance.getCompanyLanguage({ companyId: company_id });
      const { logo, ...company } = data.value;
      this.create_company = company;
      this.logo_url = logo;
      this.create_company.hide_catalog_prices = hiddenPrices.data.value.hide_catalog_prices;
      this.create_company.language = language;
    },
    // Create function for update company
    async updateCompany(companyId: string) {
      const companiesApi = useCompaniesApi();
      const feedback = useFeedback();

      // eslint-disable-next-line
      const { company_id, ...payload } = this.create_company;
      const { data, error } = await companiesApi.updateCompany(payload, companyId);
      if (data.value) {
        if (this.logo) {
          await this.updateCompanyLogo(companyId);
        }
        this.show_create_modal = false;
        this.is_update = false;
        feedback.success("L'entreprise a bien été mise à jour", "small");
      } else {
        this.errors.update_company = error.value.data.message;
        feedback.error(`Une erreur est survenue lors de l'ajout`, "small");
      }
    },
  },
});
