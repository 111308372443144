export enum CompanyIdentificationModeEnum {
  EMAIL = "email",
  EMPLOYEE_NUMBER = "employee_number",
}

export enum CompanyAuthStrategy {
  LOGIN_PASSWORD = "email_password",
  SSO_SAML = "sso_saml",
}

export enum CompanyLanguage {
  FR = "fr",
  EN = "en",
}
