import { useAuthStore } from "~~/store/auth/auth.store";

export const useRudderstack = () => {
  // We can't use the auth store because in some cases, the user is not logged in
  function identify(userEmail: string) {
    const { $Rudderstack } = useNuxtApp() as unknown as { $Rudderstack: any };
    const { $config } = useNuxtApp() as unknown as { $config: any };
    if (["DEV", "PROD"].includes($config.public.ENV)) {
      $Rudderstack.identify(userEmail);
    }
  }

  function track(eventName: string, params?: any) {
    const { $Rudderstack } = useNuxtApp() as unknown as { $Rudderstack: any };
    const { $config } = useNuxtApp() as unknown as { $config: any };
    if (["DEV", "PROD"].includes($config.public.ENV)) {
      const currentUserTraits = $Rudderstack.getUserTraits();
      const currentUserId = $Rudderstack.getUserId();
      if (!currentUserTraits?.companyName || !currentUserTraits?.roles) {
        identifyWithTraits();
      }
      if (!currentUserId) {
        identifyUserFromAuthStore();
      }

      $Rudderstack.track(eventName, params);
    }
  }

  function identifyUserFromAuthStore() {
    const authStore = useAuthStore();
    const currentUserEmail = authStore.user?.email;
    if (currentUserEmail) {
      identify(currentUserEmail);
    }
  }

  function page(path: string) {
    const { $Rudderstack } = useNuxtApp() as unknown as { $Rudderstack: any };
    const { $config } = useNuxtApp() as unknown as { $config: any };

    if (["DEV", "PROD"].includes($config.public.ENV)) {
      const currentUserId = $Rudderstack.getUserId();
      const currentUserTraits = $Rudderstack.getUserTraits();

      if (!currentUserTraits?.companyName || !currentUserTraits?.roles) {
        identifyWithTraits();
      }

      if (!currentUserId) {
        identifyUserFromAuthStore();
      }
      $Rudderstack.page(path);
    }
  }

  function identifyWithTraits() {
    const authStore = useAuthStore();
    const currentUserEmail = authStore.user?.email;
    const companyName = authStore.user?.main_entity_id?.name;
    const roles = authStore.user?.roles;

    const { $Rudderstack } = useNuxtApp() as unknown as { $Rudderstack: any };
    const { $config } = useNuxtApp() as unknown as { $config: any };
    if (["DEV", "PROD"].includes($config.public.ENV)) {
      $Rudderstack.identify(currentUserEmail, { companyName, roles });
    }
  }

  return {
    identify,
    track,
    page,
  };
};
